<template>
    <div class="hotspeciality">
        <div class="specialitybox">
            <div class="specialitycon">
                <div class="titleico">
                    <div class="ico"><i></i><span>{{ ModuleName }}</span>
                        <div class="clear"></div>
                    </div>
                    <div class="switch">
                        <!-- <span @click="change">换一批</span>
                        <i class="iconfont">&#xe627;</i> -->
                    </div>
                    <div class="order" @click="majorRank">
                        <i class="iconfont">&#xe66d;</i>
                        <span>专业排行榜</span>
                    </div>
                    <div class="more" @click="hotsmorebtn()">更多<i class="iconfont">&#xe600;</i></div>
                    <div class="clear"></div>
                </div>
                <div class="specialitylist">
                    <div class="sybox" v-for="item in MajorHotData" :key="item.Id">
                        <div class="box" @click="openDetail(item)">
                            <div class="sname nowrap">{{ item.MajorName }}</div>
                            <div class="numtxt">代码</div>
                            <div class="code">{{ item.NationalCode }}</div>
                            <div class="stype">{{ item.CategoryName }}</div>
                            <div class="ani_l"></div>
                            <div class="ani_t"></div>
                            <div class="ani_r"></div>
                            <div class="ani_b"></div>

                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import API from '@/api/config'
export default {
    props: {
        ModuleName: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            MajorHotData: []
        }
    },
    mounted() {
        this.initialize()
    },
    methods: {
        change() {

        },
        // 详情
        openDetail(item) {
            console.log(item)
            this.$router.push({
                path: '/query/majordetail',
                query: {
                    id: item.Id
                }
            })
        },
        // more
        hotsmorebtn() {
            this.$router.push('/query/major')
        },

        // 初始数据
        initialize() {
            API.Query.GetMajorHotList(6).then(res => {
                this.MajorHotData = res.Data;
            })
        },
        majorRank(){
            this.$router.push('/query/majorrank')
        }
    }
}
</script>
<style lang="less" scoped>
.hotspeciality {
    width: 100%;
    height: 260px;
    margin-top: 25px;
    user-select: none;

    .specialitybox {
        max-width: 1420px;
        margin: auto;

        .specialitycon {
            margin: 0px 20px;
            border: 1px solid #eee;
            box-shadow: 0px 0px 3px 1px #ebeaea;

            .titleico {
                height: 50px;
                line-height: 48px;
                background-color: #f2f6fa;
                overflow: hidden;
                border-bottom: 1px solid #ebebeb;

                .ico {
                    float: left;

                    i {
                        float: left;
                        margin: 15px 15px 0px 20px;
                        width: 6px;
                        height: 19px;
                        background-color: rgb(var(--themecolor));
                    }

                    span {
                        font-size: 16px;
                        color: #555;
                        font-weight: bold;
                    }
                }

                .switch {
                    float: left;
                    margin-left: 30px;
                    padding: 0px 10px;

                    i {
                        font-size: 14px;
                        color: #666;
                        margin-right: 6px;
                        float: left;
                        margin-top: 2px;
                        opacity: 0.5;
                        transition: all 0.3s;
                        -webkit-transition: all 0.3s;
                    }

                    span {
                        font-size: 13px;
                        color: #666;
                        line-height: 50px;
                    }
                }

                .switch:hover {
                    cursor: pointer;
                }

                .switch:hover i {
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                }

                .switch:hover span {
                    color: rgb(var(--themecolor));
                }
                .order {
                    float: left;
                    margin-left: 30px;
                    padding: 0px 10px;
                    cursor: pointer;

                    i {
                        font-size: 18px;
                        color: #f00707;
                        margin-right: 6px;
                        float: left;
                        margin-top: 2px;
                        opacity: 0.5;
                        line-height: 48px;
                    }

                    span {
                        font-size: 15px;
                        color: #666;
                        line-height: 52px;
                        font-weight: bold;
                    }
                }

                .order:hover span {
                    color: rgb(var(--themecolor));
                }
                .more {
                    float: right;
                    width: 54px;
                    height: 100%;
                    line-height: 50px;
                    color: #666;
                    font-size: 12px;
                    text-align: center;
                    margin-right: 17px;

                    i {
                        display: block;
                        transform: rotate(90deg);
                        -webkit-transform: rotate(270deg);
                        float: right;
                        color: #888;
                        font-size: 14px;
                    }
                }

                .more:hover {
                    cursor: pointer;
                    color: rgb(var(--themecolor));
                }
            }

            .specialitylist {
                margin: 0px 10px;
                background-color: #fff;

                .sybox {
                    width: calc(100% / 6);
                    height: 200px;
                    float: left;

                    .box {
                        margin: 22px 10px 0;
                        border: 1px solid #e7e7e7;
                        background-color: #fff;
                        text-align: center;
                        position: relative;
                        overflow: hidden;

                        .sname {
                            color: #333;
                            font-size: 16px;
                            line-height: 30px;
                            margin-top: 12px;
                        }

                        .numtxt {
                            color: #666;
                            font-size: 12px;
                            margin-top: 10px;
                        }

                        .code {
                            color: rgb(var(--themecolor));
                            opacity: 0.75;
                            font-size: 22px;
                            margin-top: 5px;
                        }

                        .stype {
                            color: #666;
                            font-size: 12px;
                            line-height: 20px;
                            height: 40px;
                            overflow: hidden;
                            margin-top: 10px;
                        }

                        .ani_l,
                        .ani_t,
                        .ani_r,
                        .ani_b {
                            position: absolute;
                            background-color: rgb(var(--themecolor));
                            opacity: 0.6;
                        }

                        .ani_l {
                            height: 100%;
                            width: 1px;
                            left: 0px;
                            top: 100%;
                            transition: top 0.3s;
                            -webkit-transition: top 0.3s;
                        }

                        .ani_t {
                            height: 1px;
                            width: 100%;
                            right: 100%;
                            top: 0px;
                            transition: right 0.3s;
                            -webkit-transition: right 0.3s;
                        }

                        .ani_r {
                            height: 100%;
                            width: 1px;
                            right: 0px;
                            bottom: 100%;
                            transition: bottom 0.3s;
                            -webkit-transition: bottom 0.3s;
                        }

                        .ani_b {
                            height: 1px;
                            width: 100%;
                            left: 100%;
                            bottom: 0px;
                            transition: left 0.3s;
                            -webkit-transition: left 0.3s;
                        }
                    }

                    .box:hover {
                        cursor: pointer;
                        opacity: 0.8;
                    }

                    .box:hover .ani_l {
                        top: 0px;
                    }

                    .box:hover .ani_t {
                        right: 0px;
                    }

                    .box:hover .ani_r {
                        bottom: 0px;
                    }

                    .box:hover .ani_b {
                        left: 0px;
                    }
                }
            }
        }
    }
}</style>